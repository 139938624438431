<template>
  <div>
    <el-carousel
      direction="vertical"
      trigger="click"
      :autoplay="true"
      :interval="carTime"
      class="carouselBox"
      ref="lunbo"
      @change="carouselChange"
    >
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <div v-if="item.storyType == 'VIDEO'" class="video_box" style="display: flex;align-items: flex-end;height: 48.80208vw;overflow: hidden">
          <video ref="videoPlayer"  type="video/mp4"  :poster="item.thumbnailInfo.url" :src="item.mapProperties.accessUrl" autoplay loop
                 muted  style="cursor: pointer;width: 100%;" @click="toDetail(item)"></video>
        </div>

        <img :src="item.thumbnailInfo.url" @click="toDetail(item)" style="cursor: pointer;"/>
      </el-carousel-item>
    </el-carousel>
    <div class="btndiv">
      <div class="btn" @click="prev()">
        <img src="../assets/img/prev.png" />
      </div>
      <div class="btn" @click="next()">
        <img src="../assets/img/next.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerList:[],
      carTime:6000,
    }
  },
  mounted() {
    this.getHomePage()
  },
  methods: {
    prev() {
      this.$refs.lunbo.prev();
    },
    next() {
      this.$refs.lunbo.next();
    },
    carouselChange(cerIndex){
      if(this.bannerList[cerIndex].videoTime){
       this.carTime = Number(this.bannerList[cerIndex].videoTime)*1000
      }else{
        this.carTime = 6000
      }
      console.log(this.carTime)
    },
    getHomePage(){
      // const params = {
      //   id: '',
      //   type: '', //1：图片+url，2：稿件，3：专题
      //   imageUrl: '',
      //   homeName: '',
      //   storyUrl: '',
      //   moduleType:1
      //   // delFlag: 0, //0：正常，1：删除）,
      // };
      // const res = await this.API.basic.getlistMenuHome(params);
      const params = {
        pageNumber: 0,
        pageSize: 10,
        columnIds: this.$columnIdFile.homeRotograph
      }
      this.API.basic.getHomeRotographList(params).then(res => {
        // this.bannerList = res.data.content
        let list = []
        res.data.content.forEach(async item=>{
          if(item.thumbnailInfo.url) {
            item.imageUrl = item.thumbnailInfo.url
            list.push(item)
          }
        })
        this.bannerList = list.slice(0, 5)
        this.carouselChange(0)
      })
    },
    toDetail(item){
      if (item.mapProperties.erOriginLink) {
        window.open(item.mapProperties.erOriginLink)
      }else{
        // this.$router.push({
        //   path:'/gjDetail',
        //   query: {
        //     id: item.id
        //   }
        // })
        this.$router.push({
          path:'/newsDetail',
          query: {
            id: item.id
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .carouselBox {
    height: 937px;
    .video_box{
      height: 937px!important;
    }
    ::v-deep .el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btndiv {
    position: absolute;
    left: 0;
    bottom: 50%;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px 0 30px;
    .btn {
      cursor: pointer;
    }
  }
  //::v-deep .el-carousel__item {
  //  display: flex;
  //  align-items: flex-end;
  //  //flex-wrap: wrap;
  //  overflow-y: hidden;
  //}
</style>
