<template>
  <div class="homePage">
    <!-- 轮播图 -->
    <div class="lb">
      <carousel />
      <!-- <el-carousel
        direction="vertical"
        trigger="click"
        :autoplay="true"
        :interval="carTime"
        class="carouselBox"
        ref="lunbo"
        @change="carouselChange"
      >
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <video v-if="item.storyType == 'VIDEO'" :poster="item.thumbnailInfo.url" :src="item.storyResource.info" autoplay loop
      muted  style="cursor: pointer;width: 100%;" @click="toDetail(item)"></video>
          <img :src="item.thumbnailInfo.url" @click="toDetail(item)" style="cursor: pointer;"/>
        </el-carousel-item>
      </el-carousel> -->
      <!-- <div class="btndiv">
        <div class="btn" @click="prev()">
          <img src="../assets/img/prev.png" />
        </div>
        <div class="btn" @click="next()">
          <img src="../assets/img/next.png" />
        </div>
      </div> -->
    </div>
    <!-- <h1>{{$t('name')}}</h1> -->
    <!-- <h1>{{$t('m.a')}}</h1> -->
    <!-- 新闻板块 -->
    <div class="news 1">
      <div class="w1380">
        <div class="topImg">
          <img src="../assets/img/news.png" />
        </div>
        <div class="biaoti">
          <div class="biaoti-left">
            <img src="../assets/img/newsIcon.png" />
            <span>{{ $t("importantNews") }}</span>
          </div>
          <div class="biaoti-right" @click="toNewsList($t('importantNews'))">
            {{ $t("viewMore") }} >
          </div>
        </div>
        <div class="xw-list">
          <div class="xw-list-top">
            <div class="xw-list-top-left" v-if="ywsdList.length > 0">
              <template v-if="ywsdList[0].mapProperties.erOriginLink">
                <a
                  class="xw-list-top-left-img 11"
                  :href="ywsdList[0].mapProperties.erOriginLink"
                  target="_blank"
                >
<!--                  <div v-if="ywsdList[0].columnIsTop" class="column_is_top">-->
<!--                    {{ $t("top") }}-->
<!--                  </div>-->
                  <template v-if="ywsdList[0].thumbnailInfo">
                    <img :src="ywsdList[0].thumbnailInfo.thumbnailUrl" />
                  </template>
                  <div class="nr">
                    <div class="nr-left">
                      <span>{{
                        $moment(ywsdList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .split(".")[2]
                      }}</span
                      ><br /><span>{{
                        $moment(ywsdList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .slice(0, 7)
                      }}</span>
                    </div>
                    <div class="nr-right">
                      <div class="nr-right-bt ellipsis3">
                        {{ ywsdList.length > 0 ? ywsdList[0].title : "" }}
                      </div>
                    </div>
                  </div>
                </a>
              </template>
              <template v-else>
                <div
                  class="xw-list-top-left-img 11"
                  @click="toNewsDetail(ywsdList[0])"
                >
<!--                  <div v-if="ywsdList[0].columnIsTop" class="column_is_top">-->
<!--                    {{ $t("top") }}-->
<!--                  </div>-->
                  <template v-if="ywsdList[0].thumbnailInfo">
                    <img :src="ywsdList[0].thumbnailInfo.thumbnailUrl" />
                  </template>
                  <div class="nr">
                    <div class="nr-left">
                      <span>{{
                        $moment(ywsdList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .split(".")[2]
                      }}</span
                      ><br /><span>{{
                        $moment(ywsdList[0].publishTime)
                          .format("YYYY.MM.DD")
                          .slice(0, 7)
                      }}</span>
                    </div>
                    <div class="nr-right">
                      <div class="nr-right-bt ellipsis3">
                        {{ ywsdList.length > 0 ? ywsdList[0].title : "" }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="xw-list-top-right" v-if="ywsdList.length > 2">
              <div
                class="xw-list-top-right-item 11"
                v-for="(item, index) in ywsdList.slice(2, 4)"
                :key="index"
              >
<!--                <div v-if="item.columnIsTop" class="column_is_top">-->
<!--                  {{ $t("top") }}-->
<!--                </div>-->
                <div class="shijian">
                  <div class="xw-list-sj-1">
                    {{
                      $moment(item.publishTime)
                        .format("YYYY.MM.DD")
                        .split(".")[2]
                    }}
                  </div>
                  <div class="xw-list-sj-2">
                    {{
                      $moment(item.publishTime).format("YYYY.MM.DD").slice(0, 7)
                    }}
                  </div>
                </div>
                <template v-if="item.mapProperties.erOriginLink">
                  <a
                    class="xw-list-2"
                    :href="item.mapProperties.erOriginLink"
                    target="_blank"
                  >
                    <div class="xw-list-2-bt ellipsis2">
                      <!-- <a href="/" class="ellipsis2"> -->
                      {{ item.title }}
                      <!-- </a> -->
                    </div>
                    <div class="xw-list-2-nr ellipsis2">
                      {{ item.description }}
                    </div>
                  </a>
                </template>
                <template v-else>
                  <div class="xw-list-2" @click="toNewsDetail(item)">
                    <div class="xw-list-2-bt ellipsis2">
                      <!-- <a href="/" class="ellipsis2"> -->
                      {{ item.title }}
                      <!-- </a> -->
                    </div>
                    <div class="xw-list-2-nr ellipsis2">
                      {{ item.description }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="xw-list-bottom">
            <ul class="xw-list-bottom-left 11" v-if="ywsdList.length > 4">
              <li v-for="(item, index) in ywsdList.slice(4, 8)" :key="index">
                <div v-if="item.columnIsTop" class="column_is_list_top">
                  {{ $t("top") }}
                </div>
                <div class="shijian">
                  <p class="xw-list-sj-1">
                    {{
                      $moment(item.publishTime)
                        .format("YYYY.MM.DD")
                        .split(".")[2]
                    }}
                  </p>
                  <p class="xw-list-sj-2">
                    {{
                      $moment(item.publishTime).format("YYYY.MM.DD").slice(0, 7)
                    }}
                  </p>
                </div>
                <template v-if="item.mapProperties.erOriginLink">
                  <a
                    class="xw-list-tit ellipsis4"
                    :href="item.mapProperties.erOriginLink"
                    target="_blank"
                  >
                    {{ item.title }}
                  </a>
                </template>
                <template v-else>
                  <div
                    class="xw-list-tit ellipsis4"
                    @click="toNewsDetail(item)"
                  >
                    {{ item.title }}
                  </div>
                </template>
              </li>
            </ul>
            <template v-if="ywsdList.length > 1">
              <template v-if="ywsdList[1].mapProperties.erOriginLink">
                <a
                  class="xw-list-top-left"
                  :href="ywsdList[1].mapProperties.erOriginLink"
                  target="_blank"
                >
                  <div class="xw-list-top-left-img 22">
<!--                    <div v-if="ywsdList[0].columnIsTop" class="column_is_top">-->
<!--                      {{ $t("top") }}-->
<!--                    </div>-->
                    <template v-if="ywsdList[1].thumbnailInfo">
                      <img :src="ywsdList[1].thumbnailInfo.thumbnailUrl" />
                    </template>
                    <div class="nr">
                      <div class="nr-left">
                        <span>{{
                          $moment(ywsdList[1].publishTime)
                            .format("YYYY.MM.DD")
                            .split(".")[2]
                        }}</span
                        ><br /><span>{{
                          $moment(ywsdList[1].publishTime)
                            .format("YYYY.MM.DD")
                            .slice(0, 7)
                        }}</span>
                      </div>
                      <div class="nr-right">
                        <div class="nr-right-bt ellipsis3">
                          {{ ywsdList[1].title }}
                        </div>
                        <!-- <div class="nr-right-nr">
                          10月24日晚，学校操场灯光璀璨、热闹非凡，舞台射灯“点亮”夜空。值此北京第二外国语学院建校56周年之际，“More
                          Than 1024”草地歌舞会在师生们的热切期待中终于拨开面纱
                        </div> -->
                      </div>
                    </div>
                  </div>
                </a>
              </template>

              <template v-else>
                <div
                  class="xw-list-top-left"
                  @click="toNewsDetail(ywsdList[1])"
                >
                  <div class="xw-list-top-left-img 22">
<!--                    <div v-if="ywsdList[0].columnIsTop" class="column_is_top">-->
<!--                      {{ $t("top") }}-->
<!--                    </div>-->
                    <template v-if="ywsdList[1].thumbnailInfo">
                      <img :src="ywsdList[1].thumbnailInfo.thumbnailUrl" />
                    </template>
                    <div class="nr">
                      <div class="nr-left">
                        <span>{{
                          $moment(ywsdList[1].publishTime)
                            .format("YYYY.MM.DD")
                            .split(".")[2]
                        }}</span
                        ><br /><span>{{
                          $moment(ywsdList[1].publishTime)
                            .format("YYYY.MM.DD")
                            .slice(0, 7)
                        }}</span>
                      </div>
                      <div class="nr-right">
                        <div class="nr-right-bt ellipsis3">
                          {{ ywsdList[1].title }}
                        </div>
                        <!-- <div class="nr-right-nr">
                          10月24日晚，学校操场灯光璀璨、热闹非凡，舞台射灯“点亮”夜空。值此北京第二外国语学院建校56周年之际，“More
                          Than 1024”草地歌舞会在师生们的热切期待中终于拨开面纱
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 通知公告 -->
    <div class="notice">
      <div class="w1380">
        <div class="not-div1">
          <img src="../assets/img/sy-gg-t.png" />
          <p class="not-div1-tit" @click="toNewsList($t('notificationAnnoun'))">
            <img src="../assets/img/noticeIcon.png" />
            <a style="color: #fff">{{ $t("notificationAnnoun") }}</a>
          </p>
        </div>
        <ul class="not-div2">
          <li
            v-for="(item, index) in tzggList.slice(0, 6)"
            :key="index"
            style="color: #fff"
          >
            <template v-if="item.mapProperties.erOriginLink">
              <a :href="item.mapProperties.erOriginLink" target="_blank">
                <div v-if="item.columnIsTop" class="column_tagg_is_top">
                  {{ $t("top") }}
                </div>
                <div class="not-div2-tit">
                  <span>{{ item.title }}</span>
                </div>
                <div class="not-div2-shijian">
                  {{ $moment(item.publishTime).format("YYYY-MM-DD") }}
                </div>
              </a>
            </template>
            <template v-else>
              <div @click="toNewsDetail(item)">
                <div v-if="item.columnIsTop" class="column_tagg_is_top">
                  {{ $t("top") }}
                </div>
                <div class="not-div2-tit">
                  <span>{{ item.title }}</span>
                </div>
                <div class="not-div2-shijian">
                  {{ $moment(item.publishTime).format("YYYY-MM-DD") }}
                </div>
              </div>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <!-- 学术与媒体 -->
    <div class="vm">
      <div class="w1380">
        <div class="viewpoint">
          <div class="view-div1">
            <div class="view-div1-tit">
              <img src="../assets/img/viewpointIcon.png" />{{
                $t("academicTrends")
              }}
            </div>
            <a class="viewMore" @click="toNewsList($t('academicTrends'))"
              >{{ $t("viewMore") }}<img src="../assets/img/more.png"
            /></a>
          </div>
          <ul class="view-div2">
            <li
              v-for="(item, index) in xsdtList.slice(0, 4)"
              :key="index"
              style="color: #fff"
            >
              <template v-if="item.mapProperties.erOriginLink">
                <a :href="item.mapProperties.erOriginLink" target="_blank">
                  <div v-if="item.columnIsTop" class="column_is_top">
                    {{ $t("top") }}
                  </div>
                  <span>{{ item.title }}</span>
                </a>
              </template>
              <template v-else>
                <div @click="toNewsDetail(item)">
                  <div v-if="item.columnIsTop" class="column_is_top">
                    {{ $t("top") }}
                  </div>
                  <span>{{ item.title }}</span>
                </div>
              </template>
            </li>
          </ul>
        </div>
        <div class="media">
          <div class="med-div1">
            <div class="view-div1-tit">
              <img
                src="../assets/img/mediaIcon.png"
                style="margin-right: 0.52083vw"
              />{{ $t("mediaEW") }}
            </div>
            <a class="viewMore" @click="toNewsList($t('mediaEW'))"
              >{{ $t("viewMore") }}<img src="../assets/img/more.png"
            /></a>
          </div>
          <ul class="med-div2">
            <li v-for="(item, index) in mtewList.slice(0, 4)" :key="index">
              <template v-if="item.mapProperties.erOriginLink">
                <a
                  :href="item.mapProperties.erOriginLink"
                  target="_blank"
                  style="width: 100%; display: flex"
                >
                  <div v-if="item.columnIsTop" class="column_mtew_is_top">
                    {{ $t("top") }}
                  </div>
                  <template v-if="item.thumbnailInfo">
                    <img :src="item.thumbnailInfo.thumbnailUrl" />
                  </template>
                  <span class="ellipsis3">{{ item.title }}</span>
                </a>
              </template>
              <template v-else>
                <div
                  @click="toNewsDetail(item)"
                  style="position: relative; font-size: 0.9375vw; display: flex"
                >
                  <div v-if="item.columnIsTop" class="column_mtew_is_top">
                    {{ $t("top") }}
                  </div>
                  <template v-if="item.thumbnailInfo">
                    <img :src="item.thumbnailInfo.thumbnailUrl" />
                  </template>
                  <span class="ellipsis3">{{ item.title }}</span>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 校园看点 -->
    <div class="xykd">
      <div class="w1380">
        <div class="xykd-div1">
          <el-carousel trigger="click" height="7.8125vw" arrow="never">
            <el-carousel-item>
              <!-- <div class="title">明德 勤学 求是 竞先</div> -->
              <div class="title"><img src="../assets/img/sjrw.png" /></div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="xykd-div2" @click="toNewsList($t('schoolFocus'))">
          <div class="xykd-div2-1">{{ $t("schoolFocus") }}</div>
        </div>
        <!-- 焦点轮播 -->
        <div class="xwxq">
          <div class="xwxq-top" id="by">
            <div id="focusImage" class="slide">
              <ul class="contents">
                <li
                  v-for="(item, index) in xykdList.slice(0, 5)"
                  :key="index"
                  :class="index == currentIdx ? 'current' : ''"
                >
                  <template v-if="item.mapProperties.erOriginLink">
                    <a :href="item.mapProperties.erOriginLink" target="_blank">
                      <div class="image" v-if="item.thumbnailInfo">
                        <a href="#"
                          ><img
                            :src="item.thumbnailInfo.thumbnailUrl"
                            width="770"
                            height="460"
                        /></a>
                      </div>
                      <div class="text">
                        <span class="title ellipsis2" style="height: 4.6875vw">
                          <a>{{ item.title }}</a>
                        </span>
                        <p class="ellipsis2" style="height: 2.76042vw">
                          {{ item.description }}
                        </p>
                        <span class="xq"
                          ><a>{{ $t("viewDetails") }}&gt;</a></span
                        >
                      </div>
                    </a>
                  </template>
                  <template v-else>
                    <div @click="toNewsDetail(item)">
                      <div class="image" v-if="item.thumbnailInfo">
                        <a href="#"
                          ><img
                            :src="item.thumbnailInfo.thumbnailUrl"
                            width="770"
                            height="460"
                        /></a>
                      </div>
                      <div class="text">
                        <span class="title ellipsis2" style="height: 4.6875vw">
                          <a>{{ item.title }}</a>
                        </span>
                        <p class="ellipsis2" style="height: 2.76042vw">
                          {{ item.description }}
                        </p>
                        <span class="xq"
                          ><a>{{ $t("viewDetails") }}&gt;</a></span
                        >
                      </div>
                    </div>
                  </template>
                </li>
              </ul>
              <div class="icon-dot">
                <div
                  class="icon-dot-item"
                  v-for="(item, index) in xykdList.slice(0, 5)"
                  :key="index"
                  @mouseover="wrapLb(index)"
                  @mouseleave="onplayLb"
                  :class="index == currentIdx ? 'current' : ''"
                >
                  <template v-if="item.mapProperties.erOriginLink">
                    <a :href="item.mapProperties.erOriginLink" target="_blank">
                      <span v-if="item.columnIsTop" class="column_dot_is_top">{{
                        $t("top")
                      }}</span>
                      <template v-if="item.thumbnailInfo">
                        <img :src="item.thumbnailInfo.thumbnailUrl" />
                      </template>
                      <div>
                        <!-- <p>
                        金牌导师访谈 |
                        专访李小卫：“夯实语言专业技能，与学生并肩作战”{{ index }}
                      </p> -->
                        <p class="xykd_type ellipsis4" style="height: 5.20833vw">
                          {{ item.title }}
                        </p>
                        <!-- <p class="xykd_zy">「我和北二外的故事」征文活动征文活动征文活动{{ index }}</p> -->
                      </div>
                    </a>
                  </template>
                  <template v-else>
                    <div @click="toNewsDetail(item)" style="padding: 0">
                      <span v-if="item.columnIsTop" class="column_dot_is_top">{{
                        $t("top")
                      }}</span>
                      <template v-if="item.thumbnailInfo">
                        <img :src="item.thumbnailInfo.thumbnailUrl" />
                      </template>
                      <div>
                        <!-- <p>
                        金牌导师访谈 |
                        专访李小卫：“夯实语言专业技能，与学生并肩作战”{{ index }}
                      </p> -->
                        <p class="xykd_type ellipsis4" style="height: 5.20833vw">
                          {{ item.title }}
                        </p>
                        <!-- <p class="xykd_zy">「我和北二外的故事」征文活动征文活动征文活动{{ index }}</p> -->
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 二外启航 -->
    <div class="ewqh">
      <div class="w1380">
        <div class="sptk">
          <div style="position: relative; width: 100%; height: 100%">
            <div class="video">
              <video
                id="video1"
                src="https://www.bisu.edu.cn/medios/2110200906114750.mp4"
                controls
                style="width: 100%"
              ></video>
              <div class="closeVideo" @click="closeVideo">
                <img src="../assets/img/close.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="ewqh-tit1">{{ $t("viewDetails_2") }}</div>
        <div class="playIcon">
          <img src="../assets/img/playIcon.png" @click="playVideo" />
        </div>
        <div class="ewqh-tit3">{{ $t("viewDetails_5") }}</div>
        <!-- <div class="ewqh-tit1">{{ $t("viewDetails_4") }}</div> -->
        <!-- <div class="ewqh-tit2">{{ $t("viewDetails_5") }}</div> -->
        <div class="ewqh-div1">
          <div class="qh-div2-1">
            <p>{{ $t("viewDetails_6") }}</p>
          </div>
          <ul class="qh-div2-2">
            <li>
              <a
                href="https://bkzs.bisu.edu.cn/col/col19781/index.html"
                target="_black"
              >
                <div class="qh-div2-2-1">
                  <img src="../assets/img/undergraduates.png" />
                </div>
                <div class="mobile_right">
                  <div class="qh-div2-2-2">
                    <p>{{ $t("viewDetails_7") }}</p>
                  </div>
                  <div class="qh-div2-2-3">{{ $t("viewDetails_8") }}</div>
                  <div class="qh-div2-2-4">{{ $t("viewDetails_9") }}</div>
                  <div class="qh-div2-2-5">{{ $t("viewDetails_10") }}&gt;</div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://yz.bisu.edu.cn/" target="_black">
                <div class="qh-div2-2-1">
                  <img src="../assets/img/graduateStu.png" />
                </div>
                <div class="mobile_right">
                  <div class="qh-div2-2-2">
                    <p>{{ $t("viewDetails_11") }}</p>
                  </div>
                  <div class="qh-div2-2-3">{{ $t("viewDetails_12") }}</div>
                  <div class="qh-div2-2-4">
                    {{ $t("viewDetails_13") }}
                  </div>
                  <div class="qh-div2-2-5">{{ $t("viewDetails_10") }}&gt;</div>
                </div>
              </a>
            </li>
            <li>
              <a href="http://admission.bisu.edu.cn/" target="_black">
                <div class="qh-div2-2-1">
                  <img src="../assets/img/interStu.png" />
                </div>
                <div class="mobile_right">
                  <div class="qh-div2-2-2">
                    <p>{{ $t("viewDetails_14") }}</p>
                  </div>
                  <div class="qh-div2-2-3">{{ $t("viewDetails_15") }}</div>
                  <div class="qh-div2-2-4">
                    {{ $t("viewDetails_16") }}
                  </div>
                  <div class="qh-div2-2-5">{{ $t("viewDetails_10") }}&gt;</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 专题 -->
    <div class="zt">
      <div class="w1380">
        <ul>
          <li v-for="(item, index) in ztlmList.slice(0, 4)" :key="index">
            <img
              :src="item.imageUrl"
              @click="
                item.type != 1
                  ? tohref(item)
                  : toNewsList('专题', item.columnId, index)
              "
            />
            <!-- <a @click="item.type==1?tohref(item):toNewsList('专题',item.columnId)">
              <div class="zt-div" >
                <div class="zt-div-icon">
                  <img :src="item.imageUrl" />
                </div>
                <div class="zt-div-tit">{{item.homeName}}</div>
                <div class="zt-div-line"><div></div></div>
               <div class="zt-div-ctx">
                  校党委理论学习中心组专题学习党的十九届五中全会精神
                </div>
                <div class="zt-div-detail">进入专题</div>
              </div>
            </a> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import carousel from "./carousel";
// import zt1 from '@/assets/img/zt1.jpg'
// import zt2 from '@/assets/img/zt2.jpg'
// import zt3 from '@/assets/img/zt3.jpg'
// import zt4 from '@/assets/img/zt4.jpg'
export default {
  name: "Home",
  components: { carousel },
  data() {
    return {
      bannerListShow: false,
      carTime: 6000,
      currentIdx: 0,
      timer: null,
      ywsdList: [],
      tzggList: [],
      xsdtList: [],
      mtewList: [],
      xykdList: [],
      ztlmList: [
        {
          columnId: this.$columnIdFile.ewztjy1,
          name: "",
          url: "",
          imageUrl: require("../assets/img/2404161032522537791.jpg"),
          type: 1,
        },
        {
          columnId: this.$columnIdFile.ewsrxx1,
          name: "",
          url: "http://news.bisu.edu.cn/col/col19141/index.html",
          imageUrl: require("../assets/img/ewzt2.jpg"),
          type: 1,
        },
        {
          columnId: this.$columnIdFile.ewxydh1,
          name: "",
          url: "https://www.bisu.edu.cn/web/d9cdydbdh",
          imageUrl: require("../assets/img/2404161034292588134.jpg"),
          type: -1,
          // type: 2
        },
        {
          columnId: this.$columnIdFile.ewjsfz1,
          name: "",
          url: "http://cfd.bisu.edu.cn/",
          imageUrl: require("../assets/img/ewzt3.jpg"),
          type: -1,
        },
      ],
      bannerList: [],
      yykdList: [],
      
    };
  },
  mounted() {
    // this.getHomePage()
    this.onplayLb();
    this.getList();
    // this.getZhuanti()
  },
  methods: {
    carouselChange(cerIndex) {
      if (this.bannerList[cerIndex].videoTime) {
        this.carTime = Number(this.bannerList[cerIndex].videoTime) * 1000;
      } else {
        this.carTime = 6000;
      }
      console.log(this.carTime);
    },
    toDetail(item) {
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
        let url =  this.$router.resolve({
            path: "/gjDetail",
            query: {
              id: item.id,
            },
          }).href;
          window.open(url)
        }
      } else {
       let url= this.$router.resolve({
          path: "/gjDetail",
          query: {
            id: item.id,
          },
        }).href;
        window.open(url)
      }
      // else{
      //   this.$router.push({
      //     path:'/ztlist',
      //     query: {
      //       title: item.homeName,
      //       columnId: item.columnId
      //     }
      //   })
      // }
    },
    //轮播图
    getHomePage() {
      // const params = {
      //   id: '',
      //   type: '', //1：图片+url，2：稿件，3：专题
      //   imageUrl: '',
      //   homeName: '',
      //   storyUrl: '',
      //   moduleType:1
      //   // delFlag: 0, //0：正常，1：删除）,
      // };
      // const res = await this.API.basic.getlistMenuHome(params);
      const params = {
        pageNumber: 0,
        pageSize: 10,
        columnIds: this.$columnIdFile.homeRotograph,
      };
      this.API.basic.getHomeRotographList(params).then((res) => {
        // this.bannerList = res.data.content
        console.log(res, "222-----------------------------");
        let list = [];
        res.data.content.forEach(async (item) => {
          if (item.thumbnailInfo) {
            if (item.thumbnailInfo.url) {
              item.imageUrl = item.thumbnailInfo.url;
              list.push(item);
            }
          }
        });
        this.bannerList = list;
        this.bannerListShow = true;
        console.log(
          this.bannerList,
          this.bannerListShow,
          "-----------------------------"
        );
        this.carouselChange(0);
      });
    },
    //专题
    async getZhuanti() {
      const params = {
        id: "",
        type: "", //1：图片+url，2：稿件，3：专题
        imageUrl: "",
        homeName: "",
        storyUrl: "",
        moduleType: 2,
        // delFlag: 0, //0：正常，1：删除）,
      };
      debugger;
      const res = await this.API.basic.getlistMenuHome(params);
      if (res) {
        this.ztlmList = res.data;
      }
    },
    getList() {
      const arr = [
        {
          name: this.$t("importantNews"),
          type: "ywsd",
          id: this.$columnIdFile.ywsd,
        },
        {
          name: this.$t("notificationAnnoun"),
          type: "tzgg",
          id: this.$columnIdFile.tzgg,
        },
        {
          name: this.$t("academicTrends"),
          type: "xsdt",
          id: this.$columnIdFile.xsdt,
        },
        { name: this.$t("mediaEW"), type: "mtew", id: this.$columnIdFile.mtew },
        {
          name: this.$t("schoolFocus"),
          type: "xykd",
          id: this.$columnIdFile.xykd,
        },
      ];
      const data = {
        pageNumber: 0,
        pageSize: 10,
        columnIds: "", //
      };
      arr.map(async (li) => {
        data.columnIds = li.id;
        // const res = await this.API.basic.getlist(data);
        const res = await this.API.basic.getHomeRotographList(data);
        if (res) {
          this[`${li.type}List`] = res.data.content;
          console.log(`${li.type}List`, this[`${li.type}List`]);
        }
      });
    },
    prev() {
      this.$refs.lunbo.prev();
    },
    next() {
      this.$refs.lunbo.next();
    },
    wrapLb(idx) {
      clearInterval(this.timer);
      this.currentIdx = idx;
    },
    onplayLb() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.currentIdx <= 3) {
          this.currentIdx++;
        } else {
          this.currentIdx = 0;
        }
      }, 3000);
    },
    // 二外启航 播放视频
    playVideo() {
      $(".sptk").slideDown(1000);
      let video = document.getElementById("video1");
      video.currentTime = 0; //将视频时间置为0
      video.play();
    },
    // 二外启航 关闭视频
    closeVideo() {
      let video = document.getElementById("video1");
      video.pause();
      $(".sptk").fadeOut(1500);
    },
    toNewsList(lm, id, index) {
      localStorage.setItem("currentIm", lm);
      if (lm == "专题") {
      let url = this.$router.resolve({
          path: "/newsList",
          query: {
            cid: id,
            status: "ztsc",
            type: index,
          },
        }).href;
        window.open(url);
      } else {
       let url =  this.$router.resolve({
          path: "/newsList",
          query: {
            cid: id,
          },
        }).href;
        window.open(url);
      }
    },
    toZTDetail(item) {
     let url = this.$router.resolve({
        path: "/ztlist",
        query: {
          title: item.homeName,
          columnId: item.columnId,
        },
      }).href;
      window.open(url);
    },
    tohref(item) {
      window.open(item.url);
    },
    toNewsDetail(item) {
      localStorage.setItem("currentIm", item.columnName);
     let url =  this.$router.resolve({
        path: "/newsDetail",
        query: {
          cid: item.columnId,
          tab: item.columnName,
          id: item.id,
        },
      }).href;
      window.open(url)
    },
  },
};
</script>
<style lang="scss" scoped>
.column_tagg_is_top {
  position: absolute;
  right: 70px !important;
  top: 6px !important;
  width: 50px;
  color: #d81e06;
  height: 50px;
  font-size: 18px;
  background: url("../assets/img/fire_fff.png") no-repeat;
  background-size: 100% 100%;
  line-height: 70px;
  font-weight: bolder;
  text-align: center;
}
.column_is_list_top {
  position: absolute;
  right: 0 !important;
  top: 6px !important;
  width: 50px;
  color: #fff;
  height: 50px;
  font-size: 20px;
  background: url("../assets/img/fire.png") no-repeat;
  background-size: 100% 100%;
  line-height: 70px;
  font-weight: bolder;
  text-align: center;
}
.column_dot_is_top {
  position: absolute;
  right: -17px !important;
  top: -28px !important;
  width: 50px;
  color: #fff;
  height: 50px;
  font-size: 20px;
  background: url("../assets/img/fire.png") no-repeat;
  background-size: 100% 100%;
  line-height: 70px;
  font-weight: bolder;
  text-align: center;
  z-index: 99;
}
.column_mtew_is_top {
  position: absolute;
  right: 80px !important;
  top: 10px !important;
  width: 50px;
  color: #fff;
  height: 50px;
  font-size: 20px;
  background: url("../assets/img/fire.png") no-repeat;
  background-size: 100% 100%;
  line-height: 70px;
  font-weight: bolder;
  text-align: center;
}
.column_is_top {
  position: absolute;
  right: 8px !important;
  top: 10px !important;
  width: 60px;
  color: #fff;
  height: 60px;
  font-size: 20px;
  background: url("../assets/img/fire.png") no-repeat;
  background-size: 100% 100%;
  line-height: 80px;
  font-weight: bolder;
  text-align: center;
}
.homePage {
  font-family: "Source Han Sans CN", "微软雅黑", "黑体" !important;
  .top {
    width: 100%;
    height: 64px;
    padding: 0 80px;
    line-height: 64px;
    background: #a12916;
    color: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .top-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .top-center ul {
    display: flex;
    align-items: center;
  }
  .top-center ul li {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 5px;
  }
  .rt {
    ul li {
      height: 22px;
      padding: 0 10px;
      margin: 0;
      border-right: 1px solid #fff;
    }
    ul li:last-of-type {
      border-right: 0;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 130px;
    padding: 0 80px;
    color: #fff;
    background: url("../assets/img/navBg1.png") center top repeat-x;
    position: fixed;
    top: 64px;
    z-index: 999;
  }
  // .pc_logo,
  // .mobile_logo {
  //   margin-top: 20px;
  // }
  .nav-rt,
  .nav-rt ul {
    display: flex;
    align-items: center;
  }
  .nav-rt ul li {
    margin: 0 20px;
    width: 100px;
    text-align: center;
    font-size: 22px;
    line-height: 130px;
  }
  .lb {
    position: relative;
  }
  .carouselBox {
    height: 937px;
    ::v-deep .el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btndiv {
    position: absolute;
    left: 0;
    bottom: 50%;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px 0 30px;
    .btn {
      cursor: pointer;
    }
  }
  .news {
    width: 100%;
    // height: 1080px;
    background: #f6f6f6 url("../assets/img/newsBg.png") no-repeat top center;
    background-size: 100% 100%;
    padding-top: 85px;
  }
  .w1380 {
    width: 1380px;
    margin: 0 auto;
  }
  .topImg {
    height: 67px;
    img {
      height: 100%;
    }
  }
  .news .biaoti {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
  .biaoti-left {
    display: flex;
    align-items: center;
    font-size: 28px;
    img {
      width: 26px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .biaoti-right {
    height: 40px;
    font-size: 16px;
    line-height: 35px;
    color: #a12916;
    cursor: pointer;
  }
  .xw-list {
    margin-top: 50px;
  }
  .xw-list-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .xw-list-top-left {
    width: 680px;
    height: 460px;
  }
  .xw-list-top-left-img {
    position: relative;
    img {
      width: 680px;
      height: 460px;
    }
  }
  .nr {
    display: flex;
    position: absolute;
    bottom: 0px;
    background: url("../assets/img/maskBg.png") no-repeat center center;
    color: #fff;
    width: 100%;
    height: 133px;
    padding-top: 30px;
  }
  .nr-left {
    width: 60px;
    height: 60px;
    text-align: center;
    margin-left: 25px;
    margin-top: 5px;
    margin-right: 25px;
  }
  .nr-left span:first-child {
    font-family: "SourceHanSansCN Medium";
    font-size: 34px;
  }
  .nr-left span:last-child {
    font-family: "SourceHanSansCN Medium";
    font-size: 12px;
  }
  .nr-right-bt {
    height: 80px;
    font-size: 18px;
    margin: 5px 0 14px;
  }
  .nr-right-bt:hover {
    color: #a12916;
  }
  .nr-right-nr {
    width: 508px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    color: #ffffff;
    line-height: 20px;
    opacity: 0.9;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .xw-list-top-right {
    width: 680px;
    height: 460px;
  }
  .xw-list-top-right-item {
    display: flex;
    height: 217px;
    background: #fff;
    padding: 40px 35px;
    margin-bottom: 25px;
    position: relative;
  }
  .xw-list-top-right .xw-list-top-right-item:hover {
    border-bottom: 6px solid #a12916;
  }
  .xw-list-top-right-item .shijian {
    color: #a12916;
    width: 60px;
    margin-top: 10px;
    text-align: center;
  }
  .xw-list-top-right-item .shijian .xw-list-sj-1 {
    font-size: 34px;
  }
  .xw-list-top-right-item .shijian .xw-list-sj-2 {
    font-size: 12px;
  }
  .xw-list-top-right .xw-list-2 {
    padding-left: 20px;
    width: 95%;
  }
  .xw-list-2-bt {
    height: 70px;
    font-size: 20px;
    color: #333;
    line-height: 36px;
    cursor: pointer;
  }
  .xw-list-2-bt:hover {
    color: #a12916;
  }
  .xw-list-2-nr {
    height: 60px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 180%;
    color: #999999;
    cursor: pointer;
  }
  .xw-list-bottom {
    display: flex;
  }
  .xw-list-bottom .xw-list-bottom-left {
    display: flex;
    flex-wrap: wrap;
    width: 700px;
  }
  .xw-list-bottom .xw-list-bottom-left li {
    display: flex;
    flex-shrink: 0;
    width: 325px;
    height: 217px;
    background-color: #fff;
    margin-right: 25px;
    margin-bottom: 25px;
    padding: 30px;
    position: relative;
  }
  .xw-list-bottom .xw-list-bottom-left li:hover {
    border-bottom: 6px solid #a12916;
  }
  .xw-list-bottom .xw-list-bottom-left .shijian {
    color: #a12916;
    width: 60px;
    margin-top: 10px;
    margin-right: 30px;
    text-align: center;
  }
  .xw-list-bottom .xw-list-bottom-left .xw-list-sj-1 {
    font-size: 34px;
  }
  .xw-list-bottom .xw-list-bottom-left .xw-list-sj-2 {
    font-size: 12px;
  }
  .xw-list-bottom .xw-list-bottom-left .xw-list-tit {
    height: 146px;
    width: 180px;
    font-size: 20px;
    line-height: 180%;
    color: #333;
  }
  .xw-list-tit a {
    font-size: 20px;
    line-height: 180%;
    color: #333;
  }
  .notice {
    background: url("../assets/img/sy-gg-bg.png") no-repeat;
    height: 465px;
    width: 100%;
    background-size: 100% 100%;
    .w1380 {
      display: flex;
      justify-content: space-between;
    }
  }
  .notice .not-div1 {
    width: 400px;
    padding-top: 120px;
  }
  .not-div1-tit {
    display: flex;
    color: #fff;
    font-size: 28px;
    margin-top: 25px;
    img {
      width: 31px;
      height: 32px;
      margin-right: 10px;
    }
  }
  .not-div2 {
    display: flex;
    flex-wrap: wrap;
    width: 850px;
    margin-top: 55px;
  }
  .not-div2 li {
    width: 424px;
    height: 120px;
    float: left;
    background: #ad2e1b url("../assets/img/sy-gg-bg-2.png") no-repeat;
    border: 1px solid #a12916;
    background-position: 95% center;
    padding: 20px 25px;
    position: relative;
    cursor: pointer;
  }
  .not-div2 .not-div2-tit {
    font-size: 16px;
    width: 270px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    a {
      line-height: 180%;
    }
  }
  .not-div2 .not-div2-shijian {
    color: #fff;
    font-size: 12px;
    margin-top: 15px;
  }
  .vm {
    background: url("../assets/img/vmAndMediaBg.png") no-repeat;
    height: 980px;
    padding-top: 45px;
    .w1380 {
      display: flex;
      justify-content: space-between;
    }
  }
  .vm .viewpoint {
    width: 660px;
  }
  .vm .view-div1 {
    display: flex;
    justify-content: space-between;
    background: url("../assets/img/Viewpoint.png") no-repeat;
    height: 90px;
    padding-top: 55px;
    font-size: 28px;
  }
  .vm .view-div1 .view-div1-tit {
    display: flex;
    align-items: center;
    img {
      width: 26px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .vm .view-div1 .viewMore {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #a12916;
    cursor: pointer;
  }
  .vm .view-div2 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 45px;
  }
  .vm .view-div2 li {
    width: 330px;
    height: 340px;
    border: 1px solid #fff;
    background: url("../assets/img/quotes.png") no-repeat;
    background-position: 30% 28%;
    padding: 160px 45px 0 45px;
    font-size: 18px;
    text-align: center;
    position: relative;
  }
  .vm .view-div2 li a {
    line-height: 180%;
  }
  .vm .view-div2 li:first-of-type {
    background-color: #7b91c1;
  }
  .vm .view-div2 li:nth-of-type(2) {
    background-color: #cb8c83;
  }
  .vm .view-div2 li:nth-of-type(3) {
    background-color: #a16055;
  }
  .vm .view-div2 li:last-of-type {
    background-color: #dca274;
  }
  .media {
    width: 660px;
  }
  .media .med-div1 {
    display: flex;
    justify-content: space-between;
    background: url("../assets/img/Media.png") no-repeat;
    height: 90px;
    padding-top: 55px;
    font-size: 28px;
  }
  .vm .med-div1 .view-div1-tit {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 25px;
      margin-right: 10px;
    }
  }
  .vm .med-div1 .viewMore {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #a12916;
    cursor: pointer;
  }
  .media .med-div2 {
    margin-top: 45px;
  }
  .media .med-div2 li {
    display: flex;
    height: 170px;
    border: 1px solid #f6f6f6;
    background: #fff url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    padding: 35px 115px 35px 32px;
    position: relative;
  }
  .media .med-div2 li:hover {
    height: 170px;
    border: 1px solid #f6f6f6;
    background: #a12916 url("../assets/img/sy-vm-bg-6.png") no-repeat;
    background-position: 95% center;
    padding: 35px 115px 35px 32px;
  }
  .media .med-div2 li:hover a {
    color: #fff;
  }
  .media .med-div2 li:hover span{
    color: #fff;
  }
  .media .med-div2 li img {
    margin-right: 10px;
    width: 185px;
    height: 99px;
  }
  .media .med-div2 li a {
    vertical-align: top;
    display: inline-block;
    // padding-top: 20px;
    font-size: 18px;
    line-height: 180%;
    width: 300px;
    color: #333;
  }
  .xykd {
    padding-top: 65px;
    height: 875px;
    position: relative;
    .title {
      display: flex;
      justify-content: center;
    }
  }
  ::v-deep .el-carousel__button {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
  }
  ::v-deep .el-carousel__indicator.is-active button {
    background-color: #d8d8d8;
  }
  .xykd .xykd-div1 {
    font-size: 70px;
    color: #d8d8d8;
    text-align: center;
    height: 150px;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  .xykd .xykd-div2-1 {
    height: 40px;
    text-align: center;
    font-size: 28px;
    color: #333333;
    line-height: 40px;
  }
  .xwxq {
    margin-top: 40px;
  }
  .slide,
  .slide li {
    width: 1380px;
  }
  .slide li {
    overflow: hidden;
    display: none;
  }
  .slide .current {
    display: block;
  }
  .slide li .image {
    float: right;
    width: 690px;
    height: 460px;
  }
  .slide li .text {
    padding: 30px;
    float: left;
    background: url("../assets/img/lbl.png") no-repeat;
    background-size: 100% 100%;
    height: 460px;
    width: 690px;
    padding: 95px 45px;
  }
  .slide li .text .title {
    font-size: 24px;
    width: 570px;
    display: block;
    color: #3d3d3d;
    font-weight: bold;
    line-height: 150%;
  }
  .slide li .text .title a {
    font-size: 24px;
    color: #fff;
    line-height: 200%;
    text-indent: 0;
  }
  .slide li .text p {
    color: #fff;
    font-size: 16px;
    text-indent: 0;
    clear: both;
    line-height: 26px;
    margin-top: 20px;
  }
  .slide li .text .xq {
    font-size: 14px;
    color: #a12916;
    margin-top: 30px;
    display: block;
    background: #fff;
    width: 145px;
    height: 48px;
    line-height: 48px;
    text-align: center;
  }
  .slide li .text .xq a {
    color: #a12916;
  }
  .slide .icon-dot {
    width: 100%;
    margin-top: -80px;
  }
  .slide .icon-dot .icon-dot-item {
    float: left;
    width: 242px;
    height: 168px;
    display: inline-block;
    position: relative;
    margin: 0 17px;
    cursor: pointer;
  }
  .slide .icon-dot img {
    width: 242px;
    height: 168px;
  }
  .slide .icon-dot .icon-dot-item div {
    width: 242px;
    height: 168px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    background: #a2655c;
    opacity: 0.8;
    font-size: 22px;
    color: #fff;
    padding: 40px 20px 0;
  }
  .slide .icon-dot .icon-dot-item .xykd_type {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 25px;
  }
  .slide .icon-dot .icon-dot-item .xykd_zy {
    font-size: 14px;
    font-family: Source Han Sans CN;
    line-height: 26px;
    margin-top: 10px;
  }
  .slide .icon-dot .icon-dot-item.current {
    transition: all 0.6s;
    transform: translateY(-20px);
  }
  .slide .icon-dot .icon-dot-item p {
    font-size: 16px;
    color: #fff;
    width: 100%;
    line-height: 180%;
  }
  .ewqh {
    background: url("../assets/img/ewqhbg.png") no-repeat center center;
    height: 774px;
    padding-top: 63px;
    .ewqh-tit {
      width: 100%;
      height: 37px;
      font-size: 38px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      text-align: center;
    }
    .playIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      margin-top: 37px;
      img {
        width: 70px;
        height: 70px;
        cursor: pointer;
      }
    }
    .ewqh-tit1 {
      height: 50px;
      font-size: 50px;
      font-family: Source Han Serif CN;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-top: 23px;
    }
    .ewqh-tit2 {
      height: 19px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      text-align: center;
      margin-top: 25px;
    }
    .ewqh-tit3 {
      height: 19px;
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      text-align: center;
      margin-top: 25px;
    }
    .ewqh-div1 {
      height: 450px;
      background-color: #fff;
      box-shadow: 0px 0px 10px #e2dede;
      margin-top: 120px;
      position: relative;
    }
    .ewqh-div1 .qh-div2-1 {
      text-align: center;
      font-size: 28px;
      color: #333333;
      padding-top: 45px;
    }
    .ewqh-div1 .qh-div2-2 {
      margin-top: 50px;
    }
    .ewqh-div1 .qh-div2-2 li {
      width: 33.33%;
      float: left;
      text-align: center;
      padding: 0 80px;
    }
    .ewqh-div1 .qh-div2-2 .qh-div2-2-1 {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      img{
        width: 60px;
        height: 60px;
      }
    }
    .ewqh-div1 .qh-div2-2 .qh-div2-2-2 {
      color: #333;
      font-size: 26px;
      font-weight: 500;
    }
    .ewqh-div1 .qh-div2-2 .qh-div2-2-3 {
      font-size: 22px;
      font-weight: 500;
      color: #333333;
    }
    .ewqh-div1 .qh-div2-2 .qh-div2-2-4 {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
      margin-top: 24px;
      display: -webkit-box;
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
    }
    .ewqh-div1 .qh-div2-2 .qh-div2-2-5 {
      font-size: 16px;
      color: #888888;
      margin-top: 50px;
    }
    .ewqh-div1 .qh-div2-2 li:hover .qh-div2-2-5 {
      color: #a12916;
    }
  }
  .zt {
    background: url("../assets/img/ztbg.png") no-repeat;
    background-position-y: bottom;
    height: 1016px;
  }
  .zt ul {
    padding-top: 375px;
    margin-right: -20px;
  }
  .zt ul li {
    width: 330px;
    height: 540px;
    float: left;
    margin-right: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.6s;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .zt ul li .zt-div {
    position: relative;
    width: 330px;
    height: 540px;
    background-size: 100% 100%;
    padding-top: 172px;
    .zt-div-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .zt-div-tit {
      height: 23px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 25px;
      text-align: center;
      margin-top: 10px;
    }
    .zt-div-line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 13px;
      > div {
        width: 18px;
        height: 2px;
        background: #ffffff;
      }
    }
    .zt-div-ctx {
      width: 265px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      margin: 27px auto 0;
    }
    .zt-div-detail {
      position: absolute;
      right: 0;
      bottom: 30px;
      width: 84px;
      height: 22px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      border-bottom: 1px solid #fff;
    }
  }
  .zt ul li:nth-of-type(2) {
    margin-top: -75px;
  }
  .zt ul li:nth-of-type(4) {
    margin-top: -75px;
  }
  // .zt ul li:first-of-type .zt-div {
  //   background: url("../assets/img/zt1.png");
  // }
  // .zt ul li:nth-of-type(2) .zt-div {
  //   margin-top: -75px;
  //   background: url("../assets/img/zt1.png");
  // }
  // .zt ul li:nth-of-type(3) .zt-div {
  //   background: url("../assets/img/zt3.png");
  // }
  // .zt ul li:nth-of-type(4) .zt-div {
  //   margin-top: -75px;
  //   background: url("../assets/img/zt4.png");
  // }
  .zt ul li:hover {
    transform: scale(1.1);
  }
  .sptk {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100000;
    overflow: hidden;
    display: none;
    .video {
      width: 800px;
      height: 600px;
      position: fixed;
      top: 45%;
      left: 50%;
      margin-top: -200px;
      margin-left: -400px;
    }
    .closeVideo {
      position: absolute;
      top: 30%;
      right: -25px;
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
